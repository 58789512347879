/* Apply Nunito Sans font to the whole body */
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400; /* You can adjust this to any value from 200 to 1000 */
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
}


