.chooseUsCard{
    position: relative; 
    width: 100%;
    height: 350px;
    border-radius: 24px !important;
    background-color: white !important;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px; 
    border: none !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-top{
  margin-top: -50px;
  align-self: end;
  margin-right: -20px;
}

.chooseUsCard .card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
 .chooseUsCard .card-image {
    width: 100%;
    max-width: 140px;
    height: auto;
    border-radius: 8px;
  }

  
 .chooseUsCard .card-content {
    text-align: center;
    padding-bottom: 24px;
  }
  
 .chooseUsCard .card-content h3 {
    font-size: 1.5em;
    margin: 10px 0;
    color: black;
  }
  
 .chooseUsCard .card-content p {
    color: #666;
    font-size: 18.4px;
  }
  .card-plane {
    width: 200px;
    height: 200px;
    position: relative;
    top: -52px;
  }
  
  .card-blockchain {
    width: 400px;
    height: 200px;
    position: relative;
    top: -22px;
  }
  
  .card-lamp {
    width: 180px;
    height: 200px;
    position: relative;
    top: -28px;

  }
  
  .card-handshake {
    width: 250px;
    height: 200px;
    position: relative;
    top: -22px;
  }

  @media (max-width: 768px) {
    .chooseUsCard {
        width: 100%;
        height: auto;
        border-radius: 24px;
        background-color: rgba(106, 226, 237, 0.12);
    border: 1px solid #ddd;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    }
    .chooseUsCard .card-content p {
      font-size: 16px;
    }
    .card-plane {
      width:150px;
      height: 150px;
      position: relative;
      top: -27px;
    }
    
    .card-blockchain {
      width: 320px;
      height: 150px;
      position: relative;
      top: 0px;
    }
    
    .card-lamp {
      width: 180px;
      height: 150px;
      position: relative;
      top: -5px;
  
    }
    
    .card-handshake {
      width: 220px;
      height: 150px;
      top: 0px;
    }
  }
  