/* .contactUs{

} */

.form-div{
    width: 100%;
    height: 700px;
    background-color: #22A8B3;
    display: flex;
    align-items: center;
    padding-left: 120px;
    padding-right: 120px;
   
}
.globe-div{
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-div{
    display: flex;
    align-items: center;
    height: 550px;
    background-color: black;
    padding-left: 120px;
    padding-right: 120px;
}
.map-dubai{
    width: 33.33%;
    height: 390px;
    margin: 20px;
    background-color: black;
    border: 2px solid grey;
}

.map-details{
    color: white;
    text-align: left;
    padding-left: 30px;
    margin-bottom: 20px;
}

.mapdubai2 {
    position: relative;
    width: 33.33%; 
    height: 427px;
  }
  
  .mapdubai2 svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 20px;
  }
  
  .mapdubai2 .mapImage {
    position: absolute;
    top: 5%;
    left: 18%;
    width: 250px;
    height: 250px;
    z-index: 2;
  }
  
  .mapdubai2 .map-details {
    position: absolute;
    top: 75%;
    left: 45%;
    width: 300px;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 3;
    text-align: left;
  }
  
  .mapdubai2 .map-details h5 {
    margin: 0;
    color: grey;
    font-size: 28px;
    text-align: center;
    margin-top: 26px;
  }
  
  .mapdubai2 .map-details p {
    margin: 5px 0;
    color: grey;
  }
  
  .mapdubai-container{
    display: block;
  }
.globe-img img{
width: 100%;
height: 100%;
}
.globe-img{
  width: 500px;
  height: 450px;
}

  @media (max-width: 768px) {
  .map-div {
    display: block;
    align-items: center;
     height: 100%; 
    background-color: black;
    padding-left: 16px;
    padding-right: 16px;
  }


  .form-div {
    width: 100%;
    height: 100%;
    background-color: #22A8B3;
    display: block;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
}
.globe-div {
    width: 100%;
    height: 100%;
    margin: 0px;
}

.globe-img { 
display: none;
}

.mapdubai2 {
    position: relative;
    width: 100%; 
    height: 427px;
  }
  .contact-service-buttons button {
    margin: 2px;
    /* margin-bottom: 10px; */
  }
  }