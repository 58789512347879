  /* BlogCards.css */
  
  .blogCard {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 100%;
flex-shrink: 0;
    background-color: #fff;
    cursor: pointer;
  }
  
  .blogCard:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  

  
  .blogCard-image {
    object-fit: cover;
    width: 100%;
  }
  
  .blogCard-content {
    padding: 16px;
  }
  
  .blogCard-content h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
  
  .blogCard-content p {
    margin: 0;
    text-align: left;
    color: #8E8E8E;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  .blogCard-content p:nth-of-type(2) {
    color: #21929B;
}
.blogCard-image-container{
  height: 210px;

}
.blogCard-image-container img{
  height: 100%;
  object-fit: fill;
}
.category{
  font-size: 20px;

}
@media (max-width: 768px) {
  .blogCard {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(155, 214, 219, 0.12);;
    margin: 0px;
    margin-bottom: 0px;
}

.blogCard-content p {
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.blogCard-content h3 {
  font-size: 20px;
}
}