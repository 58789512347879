.industriesWeServe{
    padding: 40px 120px;
    background-color: #060606;

    text-align: center;
}
.industriesWeServe h2{
    color: var(--white, #FFF);

font-family: "Nunito Sans";
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
margin-bottom: 40px;
}
.industry-card{
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    gap: 40px;
    justify-content: center;
}

.icon-card23 {
    background-color: #4a4a4a;
    border-radius: 10px;
    width: 180px;
height: 195px;
    padding: 24px;
    text-align: center;
    transition: transform 0.8s ease;
    border-radius: 16px;
background: #494949;
/* box-shadow: 8.429px 4.214px 21.072px 0px rgba(34, 168, 179, 0.4); */
filter: grayscale(1);
  }
  
  .icon-card23:hover {
    transform: scale(1.05); 
    box-shadow: 8.429px 4.214px 21.072px 0px rgba(34, 168, 179, 0.4);
    border: 3px solid #22A8B3;
    filter: grayscale(0) ;
    padding: 20px;
    background-color: #FFF;
    color: #22A8B3;
  }
 
  .icon-image23 {
    width: 86px;
    height: 86px;
    margin-bottom: 10px;
  }

  .industry-card p{
    color: var(--white, #FFF);

font-family: "Nunito Sans";
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
margin: 10px auto;
  }
  .icon-card23:hover p {
    color: #22A8B3;
  }
  .icon-card23:hover .icon-image23 {
    fill: #22A8B3;
  }

  @media screen and (max-width: 768px) {
    .industriesWeServe{
      padding: 24px 16px;
    }
    .industriesWeServe h2 {
      font-size: 24px;
    }
    .industry-card {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    .icon-image23 {
      /* width: 45px;
      height: 45px; */
      margin-top: 10px;
      
    }
    .icon-card23 {
      background-color: #4a4a4a;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      padding: 0px;
      text-align: center;
      transition: transform 0.8s ease;
      border-radius: 6px;
      box-shadow: 8.429px 4.214px 21.072px 0px rgba(34, 168, 179, 0.4);
      border: 3px solid #22A8B3;
      filter: grayscale(0) ;
      background-color: #FFF;
      color: #22A8B3;
      
    }
    .industry-card p {
      font-size: 16px;
      margin: 0px;
      color: #22A8B3;
      margin-bottom: 10px;
    }
    .icon-card23:hover {
      padding: 0px;
      transform: scale(1); 
    }
    .view-more-container{
      margin: 20px 0px;
      margin-bottom: 0px;
    }
    .view-more-button {
      display: inline-block;
      justify-content: center;
      padding: 7px 10px;
      height: 30px;
      background-color: #89d7d8;
      color: #000;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      border: none;
      background: #9BD6DB;
      clip-path: polygon(8% 0, 100% 0, 100% 20%, 100% 72%, 94% 100%, 0 99%, 0% 80%, 0 24%);
      overflow: hidden; /* Ensure the button clips the animated elements */
      transition: background-color 0.3s ease;
    }
    .view-more-button::after{
      content: '↗';
      margin-left: 6px;
    }
    .view-more-btn:hover {
      background-color: #0056b3;
    }
  }