.contact-form2 {
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 20px;
text-align: left;
    background-color: white;
    width: 60%;
}
.main-container-contact{
    padding: 40px 120px ;
    display: flex;
    align-items: center;
    gap: 40px;
}
.contact-main-div{
    width: 40%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
}

.contact-main-div h4{
    font-size: 34px;
    font-weight: 600;
    text-align: left;
}
.contact-main-div p{
    font-size: 18px;
}
.contact-form2 h4 {
    margin-bottom: 10px;
    text-align: left;
}

.contact-form2 p {
    /* margin-bottom: 20px; */
    text-align: left;
}

.contact-service-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.contact-service-buttons  button {
    background-color: white;
    color:  #22A8B3;
    border: 1px solid #22A8B3;;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
}

.contact-service-buttons button.active {
    background: #22A8B3;
    color: white;
}

.form-group {
    margin-bottom: 15px;
    display: flex;

}

.form-group input,
.form-group select {
    width: calc(50% - 10px);
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    margin: 5px;
    color: grey;
}

.form-group input[type="tel"] {
    width: 50%;
}

.form-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    color: grey;
}

.privacy-policy {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 30px;
}

.privacy-policy p{
    width: 320px;
    text-align: left;
}




@media (max-width: 768px) {
    .main-container-contact{
        padding: 24px 16px ;
        flex-direction: column;
        gap: 16px;
    }
.contact-service-buttons {
    display: block;
}
.form-group input, .form-group select {
    width: 100%;
}
.contact-main-div {
width: 100%;
}
.contact-form2 {
    width: 100%;
    padding: 0px;
}
.contact-main-div p {
    margin-bottom: 0px;
    font-size: 16px;
}
.form-group {
    width: 100%;
    flex-direction: column;
}
.form-group input[type="tel"] {
    width: 100%;
}
.privacy-policy {
    margin-top: 20px;
    display: block;
    height: 40px;
    margin-bottom: 55px;
}
.contact-service-buttons button{
    font-size: 12px;
}
.contact-main-div h4 {
    font-size: 24px;
    text-align: center;
}
.hide-p-mobile{
    display: none;
}
.privacy-policy p {
    width: 100%;
    margin-bottom: 0px;
}
}