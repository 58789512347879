.header-container {
    width: 100%;
   height: 580px;
   background: #111;
   display: flex;
   align-items: center;
   box-sizing: border-box;

}

.mobile-heading{
    display: none;
}

.header-left1 {
   height: 354px;
   display: flex;
   flex-direction: column;
   text-align: left;
   padding-left: 120px;
   display: block;
   align-content: center;
   
}
.header-container.blogs-bg .header-left1{
    background: rgba(0,0,0,0.6);
}
.header-title h1 {
   color: #FFF;
   font-size: 34px;
   font-weight: 600;
   line-height: normal;
   text-transform: capitalize;
   margin: 0;

}
.header-right{
    padding-right: 120px;
    background-color: #111 !important;
}
.header-right img{
    width: 450px;
    height: 400px;
}

.header-highlight {
   display: flex;
   align-items: center;
   margin-top: 10px;
   height: 60px;
   overflow: hidden;
   position: relative;
   width: 560px;
}

.header-in {
   color: #FFF;
   font-size: 48px;
   font-weight: 900;
   line-height: normal;
   text-transform: capitalize;
   margin-right: 20px; 
}

.header-highlight-text {
    background: linear-gradient(90deg, #9BD6DB 0%, #21929B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    font-weight: 900;
    line-height: normal;
    position: relative;
    display: inline-block;
    opacity: 0; /* Start hidden */
    transform: translateY(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.text-in {
    animation: slideIn 0.5s forwards;
}

.text-out {
    animation: slideOut 0.5s forwards;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.header-description p {
   width: 464px;
   color: #FFF;
   font-size: 20px;
   font-weight: 300;
   line-height: normal;
   margin-top: 20px;
   margin-bottom: 30px;
}

.arrow-icon {
   margin-left: 8px;
}

.container {
   padding-top: 50px; 
}

/* .content {
   height: 2000px; 
} */
 /* .header-container.about-bg, .header-container.contact-bg, .header-container.services-bg, .header-container.blogs-bg{
    height: 467px;
 } */
 .header-container.services-bg h1, .header-container.contact-bg h1, .header-container.about-bg h1, .header-container.blogs-bg h1{
    background: linear-gradient(90deg, #9BD6DB 0%, #21929B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }
.header-container.services-bg img{
    width: 541px;
    height: 100%;
display: none;
}
.header-container.services-bg{
    background-image: url('../images/service-header-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header-container.blogs-bg img{
    display: none;
}
.header-container.blogs-bg{
    background-image: url('../images/blog-header-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header-container.about-bg img{
    display: none;
}
.header-container.about-bg{
    background-image: url('../images/about-header-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.header-container.contact-bg{
    background-image: url('../images/contact us.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.header-container.contact-bg img{
    display: none;
}

@media (max-width: 1024px) { 
   .header-container {
       height: auto;
       padding: 15px;
       margin: 20px auto;
       width: 100%;
   }
   .header-container {
    height: 550px;
   }

   .header-left {
       width: 100%;
       height: auto;
   }
.header-right{
    display: none;
}
   .header-title h1 {
       font-size: 28px;
   }

   .header-highlight {
       flex-direction: row;
       align-items: flex-start;
       height: auto;
       
   }

   .header-in, .header-highlight-text {
       font-size: 36px;
   }

   .header-highlight-text {
       position: relative;
       left: 0;
       margin-top: 0px;
   }

   .header-description p {
       width: 60%;
       font-size: 18px;
   }

   .contact-btn {
       width: 150px;
       height: 40px;
       font-size: 14px;
   }
}

@media (max-width: 768px) { /* Mobile */
   .header-container {
       flex-direction: column;
       padding: 10px;
       margin: 10px auto;
       width: 100%;
       text-align: left;
       height: auto;
       padding: 0px; 
       margin: 0px;
       margin-top: -2px;
       background-image: linear-gradient(90deg, 
       rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), 
       url('../images/mobile-bg22.jpg');
       background-size: cover;
       background-position-x: left;
   }

   .header-left1 {
    width: 100%;
    height: 435px;
    text-align: left;
    padding: 16px;
    padding-top: 0px;
 padding-bottom: 0px;
   }

   .header-title h1 {
       font-size: 28px;
       text-align: left;
   }

   .header-highlight {
       flex-direction: row;
       align-items: center;
       height: auto;
       margin-top: 5px;
       width: 281px;
   }
.header-in{
    margin-right: 10px;
    font-size: 24px;
}

   .header-highlight-text {
       position: relative;
       left: 0;
       margin-top: 0px;
       font-size: 24px;
       background: linear-gradient(90deg, #9BD6DB 0%, #4cadb4 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
   }

   .header-description p {
       width: 100%;
       font-size: 14px;
       text-align: left;
       width: 256px;
       padding-bottom: 10px;
       
   }

   .contact-btn {
       width: 130px;
       height: 36px;
       font-size: 12px;
       display: none;
   }


   .header-know-more {
    justify-content: flex-start;
}

.header-right{display: none;}
.header-container.home-bg {
    background-image: linear-gradient(90deg, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), url('../images/360_by_640_1.webp');
    background-size: cover;
    background-position: center;
}
.header-container.services-bg {
    background-image: linear-gradient(90deg, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), url('../images/service-mobile-bg.jpg');
    background-size: cover;
    background-position-x: left;
}
.header-container.about-bg {
    background-image: linear-gradient(90deg, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), url('../images/aboutus2.jpg');
    background-size: cover;
    background-position-x: center;
}
.header-container.portfolio-bg {
    background-image: linear-gradient(90deg, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), url('../images/service-mobile-bg.jpg');
    background-size: cover;
    background-position-x: left;
}
.header-container.contact-bg {
    background-image: linear-gradient(90deg, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0.7) 70%, rgba(6, 6, 6, 0.3) 90%), url('../images/contactus.jpg');
    background-size: cover;
    background-position-x: left;
}

}

@media (min-width: 1026px) and (max-width: 1257px) {
    .header-right img {
        width: 320px; 
        height: auto; 
    }
}


@media (min-width: 1258px) {
    .header-image {
        width: 200px; 
        height: auto; 
    }
}
@media (min-width: 765px) and (max-width: 1050px) {
    .header-container {
        height: 550px;
        width: 100%;
    }
}
 
