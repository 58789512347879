.subscription-form {
    position: relative;
    margin-top: 10px;
    text-align: center;
}

.subscription-form input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.subscription-form button {
    position: absolute;
    right: 5px;
    top: 5px;
    bottom: 5px;
    margin: auto;
    background-color: #9BD6DB;;
    color: white;
    border: none;
    border-radius: 5px 5px 5px 5px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    transition: background-color 0.3s ease;
}

.subscription-form button:hover {
    background-color: white;
    color: #9BD6DB;
    border: 0.5px solid #9BD6DB;
}

@media (max-width: 768px) {
    .subscription-form input {
        padding: 10px;
        width: 80%;
        font-size: 16px;
    }
    .subscription-form button {
        right: 84px;

    }
    .subscription-form {
        text-align: left;
    }
}
@media only screen and (max-width: 430px) {
    .subscription-form button {
        right: 84px;
    }
  }
  
  /* For Samsung Galaxy S8+ */
  @media only screen and (max-width: 360px) {
    .subscription-form button {
        right: 70px; 
    }
  }