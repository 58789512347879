/* BlogSideCard.css */

.blogSideCard {
  background: rgba(155, 214, 219, 0.12);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 auto;
  }
  
  .blogSideCard img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .blogSideCard h4 {
    font-size: 1.5em;
    margin: 20px 0;
    color: #333;
  }
  
  .blogSideCard input[type='email'] {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .blogSideCard button {
    background-color: rgba(155, 214, 219, 1);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .blogSideCard button:hover {
    background-color: rgba(135, 194, 199, 1);
    transform: scale(1.05);
  }
  
  .blogSideCard .gif {
    width: 80%;
    margin: 20px 0;
  }
  
  .blogSideCard h6 {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .icon {
    color: rgba(155, 214, 219, 1);
    font-size: 1.5em;
    transition: transform 0.2s ease;
  }
  
  .icon:hover {
    transform: scale(1.2);
  }
  
  @media (max-width: 768px) {
  .blogSideCard {
    text-align: center;
    width: 100%;
    max-width: 1050px;
    margin: 0;
    margin-top: 16px;
}
}