.offer-component {
   position: relative;
overflow: visible;
   background-color: #ffffff;
   padding: 40px;
   padding-left: 120px;
   padding-right: 120px;
     box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   font-family: "Nunito Sans", sans-serif;
   color: #111;
}

.offer-header {
   text-align: center;
   margin-bottom: 16px;
}

.offer-header h2 {
   font-size: 34px;
   font-weight: 700;
   text-transform: capitalize;
}

.offer-subheader {
   text-align: center;
   margin-bottom: 30px;
   color: #464646;
   font-size: 28px;
   font-weight: 700;
   text-transform: capitalize;
}


.offer-left,
.offer-left-2 {
   width: 50%;
   height: 340px;
   flex-shrink: 0;
   border-radius: 45px 0 0 45px;
   background-color: #9bd6db;
   padding: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.offer-left-01{
   width: 50%;
   height: 340px;
   flex-shrink: 0;
   border-radius: 0  45px 45px 0;
   background-color: #9bd6db;
   padding: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.offer-left-2 {
   border-radius: 0 45px 45px 0;
}

.offer-left-header {
   color: #ffffff;
   font-size: 30px;
   font-weight: 700;
   text-transform: capitalize;
   text-align: center;
}

.offer-right,
.offer-right-2 {
   width: 50%;
   height: 340px;
   flex-shrink: 0;
   border-radius: 0 45px 45px 0;
   border: 1px solid #9bd6db;
   background-color: #ffffff;
   padding-top: 24px;
   padding-bottom: 24px;
   padding-left: 40px;
   padding-right: 40px;
   display: flex;
   flex-direction: column;
   position: relative;

}

.offer-subheader p{
   margin-bottom: 0px;
}
.special-border .offer-right {
   border-radius: 0 45px 45px 0;
}

.offer-right-2 {
   border-radius: 45px 0 0 45px;
}


.offer-right-header {
   color: #111;
   font-size: 24px;
   font-weight: 400;
   line-height: normal;
   margin-bottom: 14px;
   text-align: left;
}

.offer-right-description {
   color: #464646;
   font-size: 18px;
   font-weight: 400;
   line-height: normal;
   text-align: left;
}



.whatOffer-btn {
   display: flex;
   justify-content: center;
   width: 100%;
   z-index: 1000; 
   margin-top: -39px;
}


.whatOffer-btn button {
  height: 40px;
}


.bitcoin{
   width: 100px;
    height: 100px;
    position: absolute;
    top: -140px;
    left: 160px;
    z-index: 100; 
}

 
 .offer-content {
   width: 100%;
   display: flex;
   align-items: center;
   margin-bottom: 145px;
   cursor: pointer;
 }
 
 .offer-right,
 .offer-right-2 {
   position: relative;
   padding: 20px;
   overflow: hidden;
 }
 
.offer-right-description button{
   border: none;
   background-color: transparent;
   color: #9bd6db;
   text-decoration: underline;
   margin-top: 10px;
}

 
 .offer-content:hover .image-wrapper,
 .offer-content:hover .image-wrapper {
   bottom: 0;
   right: 0;
   border-radius: 0;
   width: 100%;
   height: 100%;
   transform: scale(1.18);
 }
 


 
  .offer-content:hover,
.offer-content:hover {
    position: relative; 
}

.offer-content .image,
.offer-content .image {
    position: relative;
    z-index: 2; 
}

 .offer-content {
   position: relative;
}

.offer-content-svg {
   position: absolute;
   bottom: -1px;
   left: 0;
   right: 0;
   top: 415px;
   margin: auto;
   width: 92%;
}  

.hidden-svg {
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 100%;
   z-index: -1; 
}

.custom-button {
   z-index: 1; 
}
 
 
 .offer-right-header, .offer-right-description {
   position: relative;
   z-index: 1;
   transition: transform 1s ease;
 }
 .offer-right::before, .offer-right-2::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0);
   transition: background-color 1s;
   z-index: 1;
}
 .offer-content:hover .offer-right::before, .offer-content:hover .offer-right-2::before {
   background-color: rgba(0, 0, 0, 0.5); 
}

.offer-content:hover .offer-right-header {
   animation: slide-up 0.9s ease forwards;
   color: white;
   margin-top: 150px;
 }
 
 .offer-content:hover .offer-right-description {
   animation: slide-up 0.9s ease forwards;
   color: white;
   margin-top: 150px;
 }
 
 @keyframes slide-up {
   0% {
     transform: translateY(150px);
     opacity: 0;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
   }
 }
 

 /* .offer-content:hover .offer-right-header {
   animation: rotate-behind 0.4s forwards;
   color: white;
   margin-top: 150px;
   
 }
 .offer-content:hover .offer-right-description {
   animation: rotate-behind 0.4s forwards;
   color: white;
 }

 @keyframes rotate-behind {
   0% {
     transform: translateY(0) rotate(0deg);
     opacity: 0;
   }
   30% {
     transform: translateY(-150%) rotateX(90deg);
     opacity: 0;
   }
   70% {
     transform: translateY(50%) rotateX(270deg);
     opacity: 0;
   }
   100% {
     transform: translateY(0) rotateX(360deg);
     opacity: 1;
   }
 } */

 .image-wrapper {
   position: absolute;
   bottom: -47px;
   right: -30px;
   width: 230px;
   height: 230px;
   border-radius:50%;
   overflow: hidden;
   transition: width 0.9s, height 0.7s, border-radius 0.9s;
 }
 
 .image {
   width: 100%;
   height: 100%;
   display: block;
   object-fit: cover;
   transition: object-fit 0.9s, width 0.9s, height 0.7s;
   
 }

 .unique-svg-class{
   position: absolute;
   bottom: -1px;
   left: -543px;
   right: 0;
   top: 409px;
  margin: auto;
 }
 
 
@media (max-width: 768px) { 
   .offer-component {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 24px;
      padding-bottom: 24px;
   }

   .offer-right, .offer-right-2 {
      width: 50%;
      height: 190px;
      flex-shrink: 0;
      border: 1px solid #9bd6db;
      background-color: #ffffff;
      padding-top: 10px;
      padding-bottom: 14px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 0 24px 24px 0;

  }
  .offer-content-svg {
   display: none;
  }
  .offer-right-2 {
   border-radius: 24px 0 0 24px;
}

.offer-content {
    margin-top: 20px;
    margin-bottom: 24px;
}
.offer-left-01 {
   width: 50%;
   height: 190px;
   flex-shrink: 0;
   border-radius: 0 24px 24px 0;
   background-color: #9bd6db;
   padding: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.offer-right-description {
   color: #464646;
   font-size: 12px;
   font-weight: 400;
   line-height: normal;
   align-content: center;
}
.offer-right-header {
   padding: 0px;
   font-size: 10px;
   margin-top: 5px;
   margin-bottom: 5px;
   text-align: left;
   display: none;
}
.offer-left, .offer-left-2 {
   width: 50%;
   height: 190px;
   flex-shrink: 0;
   border-radius: 24px 0 0 24px;
   background-color: #9bd6db;
   padding: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.whatOffer-btn {
   display: flex;
   justify-content: center;
   padding-top: 0px;
   height: 30px;
   margin-top: 20px;

}
.whatOffer-btn button{
   height: 30px;
}
.offer-header h2 {
   font-size: 24px;
}

.offer-subheader {
   font-size: 20px;
   margin-bottom: 24px;
   font-weight: 400;
   text-transform: capitalize;
}
.offer-header {
   margin-bottom: 0px;
}
.offer-left-header {
   font-size: 14px;
   margin-bottom: 0px;
}

.image-wrapper {
   bottom: -47px;
   right: -30px;
   width: 130px;
   height: 130px;
   transition:none;
 }
 .image {
   transition: none;
 }
 .offer-right-header, .offer-right-description {
   transition: none;
 }

 .offer-right:hover .offer-right-header,
 .offer-right:hover .offer-right-description,
 .offer-right-2:hover .offer-right-header,
 .offer-right-2:hover .offer-right-description {
   animation: none;
   color: inherit;
   margin-top: initial;
 }
    .offer-component-svg{
      display: none;
      
    }
    .unique-svg-class{
display: none;
    }
    .bitcoin{
      display: none;
    }
    .offer-content:hover .image-wrapper,
    .offer-content:hover .image-wrapper {
      bottom: -47px;
      right: -30px;
      width: 130px;
      height: 130px;
      transition: none;
      border-radius: 50%;
      transform: scale(1);
    }
    .offer-right::before, .offer-right-2::before {
      background-color: rgba(0, 0, 0, 0);
   }
    .offer-content:hover .offer-right::before, .offer-content:hover .offer-right-2::before {
      background-color: rgba(0, 0, 0, 0); 
   }
   .offer-content:hover .offer-right-header {
      animation: nonee;
      color: #111;
      margin-top: 0px;
      
    }
    .offer-content:hover .offer-right-description {
      animation: none;
      color: #111;
    }

}

@media (max-width: 600px) {
   .offer-component-svg {
     display: none;
   }
   .unique-svg-class{
display: none;
    }
 }
 
 @media (min-width: 600px) and (max-width: 768px) {
   .offer-component-svg {
     display: none;
   }
   .unique-svg-class{
      display: none;
    }

 }
 

 @media (min-width: 768px) and (max-width: 992px) {
   .offer-component-svg {
     display: none;
   }
   .unique-svg-class{
display: none;
    }
   .bitcoin{
      display: none;
         }
         .offer-content{
            margin-bottom: 30px;
            width: 100%;
         }
         .offer-component{
            padding-left: 30px;
            padding-right: 30px;
         }
 }
 
 @media (min-width: 992px) and (max-width: 1200px) {
   .bitcoin {
      width: 150px; /* Increase size */
      height: 150px;
      left: calc(160px + (100vw - 1200px) / 2); 
    }

   .offer-component-svg {
     width: 85%;
   }
   .unique-svg-class{
width: 85%;
left: -337px;
    top: 394px;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media (min-width: 1200px) {

   .bitcoin {
      width: 150px;
      height: 150px;
      left: calc(160px + (100vw - 1200px) / 2);
    }
    .offer-component-svg {
      width: 95%;
    }
    .unique-svg-class{
width: 95%;
left: -434px;
    }
 }
 .mobile-view {
   display: block;
}

.desktop-view {
   display: none;
}

@media screen and (min-width: 768px) {
   .mobile-view {
       display: none;
   }

   .desktop-view {
       display: block;
   }
}