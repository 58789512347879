*{
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
  }
  .chatbot-options {
    display: flex;
    justify-content: center;
    gap: 20px;
  
}

.chatbot-option-button {
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color:cadetblue;
    color: white;
    border-radius: 5px;
}


.chatbot-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.chatbot-form input, .chatbot-form textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.chatbot-form button {
    padding: 10px;
    background-color: cadetblue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.user-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 82%;
    margin: 20px auto;
    max-height: 400px; 
    overflow-y: auto; 
}

.user-form input,
.user-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.user-form input:focus,
.user-form textarea:focus {
    border-color: cadetblue;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.user-form button {
    padding: 10px 15px;
    background-color: cadetblue;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.user-form button:hover {
    background-color: cadetblue;
}

.user-form button:active {
    background-color: cadetblue;
}

.user-form textarea {
    resize: vertical;
    min-height: 50px;
}


/* Button to toggle chatbot visibility */
.toggle-chatbot-btn {
    padding: 10px 20px;
    background-color: transparent;
    color: rgb(125, 125, 125);
    border: none;
    border-radius: 100%;
    cursor: pointer;
    font-size: 16px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 999;
}
.chat-bot-img{
   width: 135px;
    height: 130px
}
.chatbot-container {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 999;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    max-height: 200vh; 
    width: 350px;
    display: flex;
    flex-direction: column;
}

/* Chatbot header styling */
.chatbot-logo {
    width: 50px;
    height: 50px;
    display: flex;
    align-self: center;
    background-color: #000;
    border-radius: 50%;
}
.react-chatbot-kit-chat-btn-send {
    pointer-events: none;
  }  

.chatbot-header {
    background-color: cadetblue;
    padding: 10px;
    color: #f9f9f9;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 10px;
}
.react-chatbot-kit-chat-container {
    width:100% !important;
  }

/* 
.react-chatbot-kit-chat-container {
    overflow-y: auto;
    flex: 1; 
    padding: 10px;
}


.react-chatbot-kit-chat-input-container {
    border-top: 1px solid #ddd;
    padding: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
.react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: scroll;
    height: 293px !important;
  }
  .react-chatbot-kit-chat-bot-message {
    background-color: cadetblue !important;
  }

  .react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid cadetblue !important;
  }

  .react-chatbot-kit-chat-btn-send {
    background-color: cadetblue !important;
  }

  .react-chatbot-kit-chat-bot-message {
    width: 184.5px;
    margin-left: 0px !important;

  }


  /* .react-chatbot-kit-chat-btn-send {
    pointer-events: none;
    opacity: 0.5;
  }   */






  .chat-container {
    /* width: 400px; */
    /* margin: 20px auto; */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    font-family: Arial, sans-serif;
    background-color: #fff;
  }
  
  .chat-header {
    background-color: cadetblue;
    color: white;
    padding: 40px 20px;
    text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  }
  
  .chat-header img {
    width: 60px;
    height: 60px;
    background-color: black;
    border-radius: 50%;
  }
  
  .chat-header p {
    margin-bottom: 0;
  }
  
  .messages-container {
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 8px 10px;
    border-radius: 10px;
    width: fit-content;
  }
  
  .bot-icon {
    background-color: #4da6a7;
    color: #fff;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  /* Bot message bubble */
  .bot-message-bubble {
    background-color: #5ca9a8;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    text-align: left;
    max-width: 258px;
  }
  
  .bot-message-bubble::before {
    content: "";
    position: absolute;
    left: -9px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #5ca9a8;
  }
  
  /* User message bubble */
  .user-message {
    background-color: cadetblue;
    color: white;
    align-self: flex-end;
    margin-left: auto;
    padding: 10px;
    border-radius: 10px;
    /* max-width: 300px; */
    position: relative;
  }
  
  .user-message::before {
    content: "";
    position: absolute;
    right: -9px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid cadetblue;
  }
  
  /* Options button styling for bot message */
  .message.bot .options {
    display: flex;
    gap: 10px;
    padding-top: 10px;
  }
  
  .options button {
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #c1c1c1;
    
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .options button:hover {
   background-color: #4da6a7;
  }
  
  /* Input container styling */
  .input-container {
    display: flex;
    border-top: 1px solid #ddd;
  }
  
  .input-container input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
  }
  
  .input-container button {
    padding: 10px;
    background-color: cadetblue;
    color: white;
    border: none;
    cursor: pointer;
  }
  


  .chat-header {
    background-color: cadetblue;
    color: white;
    padding: 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
  z-index: 1;
  width: 349px;
  border-radius:10px 10px  0px 0px;
  }
  
  .chat-header img {
    width: 60px;
    height: 60px;
    background-color: black;
    border-radius: 50%;
  }
  
  .messages-container {
    padding: 10px;
    /* max-height: 400px; */
    overflow-y: auto;
    padding-top: 200px;
  }
  
  /* Bot message bubble */
  .bot-message-bubble {
    background-color: #5ca9a8;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    max-width: 250px;
    text-align: left;
  }
  
  .bot-message-bubble::before {
    content: "";
    position: absolute;
    left: -9px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #5ca9a8;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .bot-icon {
    background-color: #4da6a7;
    color: #fff;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  /* User message bubble */
  .user-message {
    background-color: cadetblue;
    color: white;
    align-self: flex-end;
    margin-left: auto;
    padding: 10px;
    border-radius: 10px;
    max-width: 258px;
    position: relative;
    text-align: left;
  }
  
  .user-message::before {
    content: "";
    position: absolute;
    right: -9px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid cadetblue;
  }
  
  /* Options button styling for bot message */
  .options {
    display: flex;
    gap: 10px;
    padding-top: 10px;
  }
  
  .options button {
    padding: 5px 10px;
    background-color: #c1c1c1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .options button:hover {
    background-color: #4da6a7;
  }
  
  /* Input container styling */
  .input-container {
    display: flex;
    border-top: 1px solid #ddd;
  }
  
  .input-container input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
  }
  
  .input-container button {
    padding: 10px;
    background-color: cadetblue;
    color: white;
    border: none;
    cursor: pointer;
  }

  .message {
    display: flex;
    align-items: flex-start;
    width: 95%; 
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .chat-bot-img{
        width: 120px;
         height: 120px;
         right: -34px;
         bottom: 50px;
    position: relative;
    bottom: 60px;
     }
     
     .chatbot-container {
        bottom: 100px;
     }
     .close-button2{
        position: absolute;
        bottom: 80px;
        right: 10px;
     }

  }