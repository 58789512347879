.custom-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    border: none;
    background: linear-gradient(90deg, #21929B 0%,  #8dc4c9 100%);;
    clip-path: polygon(8% 0, 100% 0, 100% 20%, 100% 72%, 94% 100%, 0 99%, 0% 80%, 0 24%);
    overflow: hidden; /* Ensure the button clips the animated elements */
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center-align text and image */
    line-height: normal; /* Ensure proper line-height for the text */
    position: relative; /* Positioning for absolute children */
}

.custom-button span {
    position: absolute;
    background-color: #21929B;
    transition: all 0.4s ease;
}

.custom-button span:nth-child(1),
.custom-button span:nth-child(3) {
    height: 4px;
    width: 0;
}

.custom-button span:nth-child(2),
.custom-button span:nth-child(4) {
    width: 4px;
    height: 0;
}

.custom-button span:nth-child(1) {
    top: 0;
   right: 0;
}

.custom-button span:nth-child(2) {
    top: 0;
    right: 0;
}

.custom-button span:nth-child(3) {
    bottom: 0;
    right: 0;
}

.custom-button span:nth-child(4) {
    bottom: 0;
    left: 0;
}
.custom-button:hover{
    background: linear-gradient(90deg, #f5f5f5ff 0%,  #f5f5f5ff 100%);;
    color: #21929B;
}
.custom-button span:nth-child(1) {
    top: 0;
    right: 0; /* Start from the right */
}

.custom-button span:nth-child(2) {
    top: 0;
    right: 0;
}

.custom-button span:nth-child(3) {
    bottom: 0;
    left: 0; /* Start from the left */
}

.custom-button span:nth-child(4) {
    bottom: 0;
    left: 0;
}
.custom-button:hover span:nth-child(3) {
    width: 100%;
}
.custom-button:hover span:nth-child(1){
    /* transform: translateY(-50%); */
    width: 100%;
}

.custom-button:hover span:nth-child(2),
.custom-button:hover span:nth-child(4) {
    height: 100%;
}

.custom-button img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-bottom: 3px;
    vertical-align: middle; 
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

.custom-button p {
    margin: 0;
    padding: 0;
    z-index: 2; /* Ensure text is on top */
}
.custom-button:hover img {
    filter: invert(48%) sepia(71%) saturate(373%) hue-rotate(154deg) brightness(89%) contrast(86%);
}

@media (max-width: 768px) {
    .custom-button {
        padding: 5px 10px;
        font-size: 12px;
    }

    .custom-button img {
        height: 14px;
        margin-bottom: 1px;
        margin-left: 0px;
        width: 20px;
    }
}
