.relatedServices__section {
    padding: 40px 120px;
    background-color: #111;
    color: #fff;
  }
  
  .relatedServices__container {
    background-color: #111;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .relatedServices__title {

    margin-bottom: 24px;
    font-family: "Nunito Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .relatedServices__description {
    font-size: 18px;
    margin-bottom: 40px;
    width: 800px;
  }
  
  .relatedServices__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .relatedServices__card {
    background-color: #2d2d2d;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    margin-bottom: 20px;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .relatedServices__card:hover {
    background-color: #353535;
  }
  
  .relatedServices__icon {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .relatedServices__cardTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .relatedServices__cardDescription {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0px;
  }
  
  @media (max-width: 768px) {
    .relatedServices__card {
      width: 100%;
      margin-bottom: 20px;
    }
    .relatedServices__description {
      font-size: 18px;
      margin-bottom: 30px;
      width: 100%;
      text-align: left;
    }
    .relatedServices__section {
      padding: 24px 16px;
    }
    .relatedServices__title {
      margin-bottom: 16px;
      font-size: 24px;
     
    }
    .relatedServices__card img{
      height: 200px;
    }
    .relatedServices__card{
      text-align: left;
    }
    .relatedServices__cardDescription {
      font-size: 16px;
      margin-bottom: 0px;
    }
    .relatedServices__cardTitle {
      font-size: 24px;
    }
  }
  