.ourMission{
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 120px;
    padding-right: 120px;
}

.ourMission-main-card{
    display: flex;
    justify-content: space-around;
    padding-top: 40px;

}
.ourMission h3{
    margin-bottom: 0px;
    color: #111;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.ourMission-card1{
width: 560px;
height: 460px;
flex-shrink: 0;
align-content: center;
}

.ourMission-card1 h4{
    width: 420px;
    color: #000000;
    font-size: 28px;
    text-align:left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ourMission-card1 p{
    color: #000;
    font-family: "Nunito Sans";
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 420px;
    margin-bottom: 24px;
}

.ourMission-card2 img{
    width: 460px;
height: 460px;
flex-shrink: 0;
}

.ourMissionbtn{
    text-align: left;
}
@media (max-width: 768px) {
    .ourMission-main-card{
        display: flex;
        flex-direction: column-reverse;
        padding-top: 20px;
    
    }
    .ourMission-card1{
        width: 100%;
        height: auto;
        flex-shrink: 0;
        align-content: center;
    }
    .ourMission-card2 img{
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }
    .ourMission-card2{
        width: 100%;
        height: auto;
        flex-shrink: 0;
        align-content: center;
    }
    .ourMission{
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 24px;
    }
    .ourMission-card1 p{
        font-size: 14px;
        width: 100%;
    }
    .ourMission-card1 h4{
        width: 100%;
        font-size: 20px;
        margin-top: 12px;

    }
    .ourMission h3 {
        font-size: 24px;
    }
  }
  