.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191919;
  color: #fff;
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  width: 100%;
  margin: 0;
  --bs-navbar-padding-x: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 120px;
  padding-right: 120px;
}

.navbar-image {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 5px;
  position: relative;
}

.navbar-image img {
  height: 60px;
  width: 60px;
}

@keyframes moveRight {
  0% {
    left: 50%; /* Start from the middle */
    opacity: 1; /* Fully visible */
  }
  50%{
    left: calc(50% + 100px); 
    opacity: 1; 
    font-size:18px;
    transform: translate(-50%, -80px);
  }
  80% {
    left: calc(50% + 150px); 
    opacity: 0; 
    font-size:16px;
    transform: translate(-50%, -80px);
  }
  100% {
    left: calc(50%+200px); 
    opacity: 0; 
  }
}


.navbar-image h1 {
  margin: 0;
  background: linear-gradient(90deg, #9bd6db 0%, #34a2aa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 800;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -70px);
  opacity: 0; /* Start with hidden text */
  transition: all 4s ease-in-out;
  animation: none; /* No animation initially */
  display: none;
}

.navbar-image.animate h1 {
  opacity: 1; /* Show text */
  animation: moveRight 5s ease-in-out forwards; /* Apply animation */
  transform: translate(-50%, -70px);
  /* font-size:32px; */
}


.navbar-buttons {
  display: flex;
  gap: 33px;
  font-weight: 800;
}

.navbar-buttons a {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

.navbar-mobile-menu {
  display: none;
}

.navbar-talk {
  display: flex;
  align-items: center;
  background-color: white;
  color: lightseagreen;
  border: 2px solid lightseagreen;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-talk:hover {
  background-color: lightseagreen;
  color: white;
}

.talk-icon {
  margin-right: 8px;
}


  /* Dropdown container */
  .services-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu12 {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #191919;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-radius: 5px;
    z-index: 1000;
    min-width: 160px;
    text-align: left;

  }
  
  /* Explicitly show dropdown menu when hover or when state is true */
  .services-dropdown:hover .dropdown-menu12,
  .dropdown-menu12.show {
    display: block;
  }
  
  /* Styling for dropdown items */
  .dropdown-menu12 a {
    display: block;
    padding: 10px 16px;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
  }
  
  .dropdown-menu12 a:hover {
    /* background-color: #f1f1f1; */
    color: #03caa9;
  }

/* Mobile view */
@media (max-width: 768px) {
  .navbar-container {
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;
  }

  .navbar {
    background-color: #191919;
    --bs-navbar-padding-x: 0;
  }

  .navbar-buttons {
    flex-direction: column;
    position: fixed;
    top: 56px;
    right: 0;
    width: 100%;
    height: calc(100vh - 56px);
    background-color: #000000;
    padding: 60px 0;
    z-index: 999;
    transform: translateX(100%);
    transition: transform 0.6s ease-in-out;
  }

  .navbar-buttons.show {
    transform: translateX(0);
  }

  .navbar-buttons.hide {
    transform: translateX(100%);
  }

  .navbar-buttons a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .navbar-buttons.show a {
    opacity: 1;
  }

  .navbar-buttons a:nth-child(1) {
    transition-delay: 0.1s;
  }

  .navbar-buttons a:nth-child(2) {
    transition-delay: 0.4s;
  }

  .navbar-buttons a:nth-child(3) {
    transition-delay: 0.7s;
  }

  .navbar-buttons a:nth-child(4) {
    transition-delay: 0.9s;
  }
  .navbar-buttons a:nth-child(5) {
    transition-delay: 1.1s;
  }
  .navbar-buttons a:nth-child(6) {
    transition-delay: 1.4s;
  }

  .navbar-mobile-menu {
    display: block;
    font-size: 25px;
    color: white;
    cursor: pointer;
  }

  .navbar-talk {
    display: none;
  }

  .navbar-image img {
    display: flex;
    height: 60px;
    width: 60px;
  }



  .navbar-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  @keyframes moveRight {
    0% {
      left: 50%; /* Start from the middle */
      opacity: 1; /* Fully visible */
    }
    50%{
      left: calc(50% + 100px); 
      opacity: 1; 
      font-size:18px;
      transform: translate(-50%, -80px);
    }
    80% {
      left: calc(50% + 150px); 
      opacity: 0; 
      font-size:16px;
      transform: translate(-50%, -80px);
    }
    100% {
      left: calc(50%+200px); 
      opacity: 0; 
    }
  }
  
  
  .navbar-image h1 {
    margin: 0;
    background: linear-gradient(90deg, #9bd6db 0%, #34a2aa 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 11px;
    font-weight: 800;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, -70px);
    opacity: 0; /* Start with hidden text */
    transition: all 4s ease-in-out;
    animation: none; /* No animation initially */
  }
  
  .navbar-image.animate h1 {
    opacity: 1; /* Show text */
    animation: moveRight 5s ease-in-out forwards; /* Apply animation */
    transform: translate(-50%, -70px);
    /* font-size:32px; */
  }
  .navbar-buttons a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    opacity: 0; /* Start hidden */
    transition: opacity 0.6s ease-in-out; /* Opacity transition */
    pointer-events: none; /* Prevent interaction when hidden */
  }
  
  .navbar-buttons.show a {
    opacity: 1; /* Fade in */
    pointer-events: auto; /* Enable interaction when shown */
  }
  
  .navbar-buttons.hide a {
    opacity: 0; /* Fade out immediately when hiding */
    transition: opacity 0s ease-in-out; /* Reset the opacity instantly on hide */
    pointer-events: none; /* Disable interaction when hidden */
  }
  
  .navbar-buttons a:nth-child(1) {
    transition-delay: 0.1s;
  }
  
  .navbar-buttons a:nth-child(2) {
    transition-delay: 0.3s;
  }
  
  .navbar-buttons a:nth-child(3) {
    transition-delay: 0.5s;
  }
  
  .navbar-buttons a:nth-child(4) {
    transition-delay: 0.7s;
  }
  
  .navbar-buttons a:nth-child(5) {
    transition-delay: 0.9s;
  }
  
  .navbar-buttons a:nth-child(6) {
    transition-delay: 1.1s;
  }
  
}
