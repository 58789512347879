.choose-us{
    background-color: black;
    color: white;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 40px;
}

.choose-us >h3{
    padding-bottom: 60px;
}
.choose-us-card-grid {
    grid-gap: 60px;
    grid-row-gap: 0;
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(2, minmax(401px, 1fr));
    margin: 0 auto;
    max-width: 1200px;
    row-gap: 0;
    width: 100%;
}

  @media (max-width: 768px) {

    .choose-us{
        margin-top: 20px;
        padding-top: 28px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .choose-us >h3 {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .choose-us-card-grid {
display: block;
margin-bottom: 20px;
        max-width: 1200px;
        row-gap: 0;
        width: 100%;
        padding-bottom: 0px;
    }

  }