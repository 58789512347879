.serviceInternal-header{
    background-color: black;
    color: #fff;
    height: 600px;
    padding: 0px 120px;
    display: flex;
    gap: 30px;
    align-items: center;
    text-align: left;
    /* background-image: url('../images/crypto-exchange-detailed.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}
.serviceInternal-Image{
    width: 50%;
    display: flex;
  justify-content: end;
}
.serviceInternal-content h3{
    font-size: 34px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}
.serviceInternal-content p{
    width: 536px;
  color: #FFF;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  margin-top: 20px;
}
.serviceInternal-Image img{
    width: 450px;
    height: 450px;
}
.serviceInternal-content{
    width: 50%;
}

.serviceInternal-div{
    display: flex;
    padding: 40px 120px;
    gap: 40px;
    align-items: center;
    text-align: left;
}

.serviceInternal-div img{
    border-radius: 20px;
   
}

.serviceInternal-div div p{
    font-size: 18px;
    margin-bottom: 0px;
}

/* .serviceInternal-div div h2{
    font-family: "Nunito Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
} */
.serviceImage-internal{
    /* height:450px; */
     width:100%;
     height: 100%;
}
.serviceImage-internal-div{
    height:500px;
        width:50%

}
.service-internal-content2{
    width: 50%;
}
@media (max-width: 768px) {
    .serviceInternal-header {
        height: 100%;
        padding: 40px 16px;
        display: flex;
        gap: 30px;
        justify-content: left;
        align-items: center;
        text-align: left;
      }
      .serviceImage-internal-div{
        height:100%;
            width:100%
    }
    .serviceImage-internal{
        height:100%;
         width:100%
    }
    .service-internal-content2 {
        width: 100%;
      }

    .serviceInternal-content {
        width: 80%;
      }
      .serviceInternal-content h3{
        font-size: 28px;

    }
    .serviceInternal-Image {
        display: none;
      }
    .serviceInternal-content p{
        font-size: 14px;
        width: 100%;
    }
    .serviceInternal-Image img{
        display: none;
    }
    .serviceInternal-div {
  flex-direction: column;
        padding: 24px 16px;
        gap: 16px;

      }
}