.footer-base{
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    height: 40px;
    background-color: black;
    color: white;
    display: flex;
    justify-content:center ;
    border: black;
}

.footer-base .allcopy{
    display: flex;
    width: 800px;
    justify-content: space-around;
    align-items: center;
}
.privacy{
    cursor: pointer;
}
@media (max-width: 768px) {
    .footer-base .allcopy {
        display: flex;
        flex-direction: row;
        width: 90%;
        justify-content: space-around; 
    }
    .footer-base{
        background-color: #353535;
    }
    .footer-base .allcopy span {
        margin: 5px 0;
        font-size: 10px;
    }
    .footer-base .allcopy span:nth-child(3){
        display: none;
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .footer-base .allcopy {
        flex-direction: row;
        width: 100%;
        height: auto;
        text-align: center;
    }

    .footer-base .allcopy span {
        margin: 5px 0;
        font-size: 10px;
    }
    .footer-base .allcopy span:nth-child(3){
        display: none;
    }
}