.ourTeam{
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 40px;
}

.ourTeam h3{
    color: #111;
font-family: "Nunito Sans";
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.ourTeam h4{
    color: #464646;
font-family: "Nunito Sans";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
}



.our-team-card-grid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

@media (max-width: 768px) {
    .ourTeam{
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 24px;
        padding-bottom: 15px;
    }
    .our-team-card-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ourTeam h3{
        font-size: 24px;
        font-weight: 700;
    }

    .ourTeam h4{
        font-size: 18px;
font-style: normal;
font-weight: 700;
    }

    /* .card-svg-team {
        display: none;
    } */
    .ourTeamCard {
        padding-bottom: 10px;
        flex-direction: column;
    }
    .card-svg-team {
        transform: rotate(90deg);
        margin-left: 0px;
        margin: 0px;
        /* margin-top: 10px; */
    }
  }
  

