.who-we-are2 {
    padding: 50px 0;
    background-color: #111;
    position: relative;
    text-align: left;
}

.who-we-are2 h2{
    text-align: center;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.who-we-are-container2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 0 120px;
    gap: 30px;
    height: 590px;
}

.image-stack2 {
    position: relative;
    top: -284px;
}

 .image-stack__item2{
    position: absolute;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 10px;
} 

.image-stack__item--top2 {
    top: 85px;
    left: 62px;
    z-index: 3;
    transform: translate(20px, -20px);
}

.image-stack__item--middle2 {
    top: 53px;
    left: 46px;
    z-index: 2;
    transform: translate(10px, -10px);
}

.image-stack__item--bottom2 {
    top: 29px;
    left: 36px;
    z-index: 1;
}

.who-we-are-text2 {
    width: 50%;
    padding-left: 40px;
}

.who-we-are-text2 h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.who-we-are-text2 h3 {
    font-size: 34px;
    font-weight: 600;
    color:#fff; /* Adjust color to match design */
    margin-bottom: 20px;
    margin-top: 30px;
}

.who-we-are-text2 p {
    font-size: 18px;
     color: #8E8E8E;;
    margin-bottom: 20px;
}

.stats2 {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;
}

.stat2 h4 {
    font-size: 28px;
    color: #21929B;
    margin-bottom: 5px;
    font-weight: 800;
}

.stat2 p {
    font-size: 14px;
    color: #6c757d;
}

.btn-primary2 {
    background-color: #21929B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.btn-primary2:hover {
    background-color: #1a737b;
}

.image-stack__item img{
    width: 490px;
  border-radius: 20px;
}
@media (max-width: 768px) { 
.who-we-are-container2 {
    padding: 0 16px;
}
.image-stack2 {
    display: none;
}
.who-we-are-text2 {
    width: 100%;
    padding-left: 0px;
}
.who-we-are2 {
    padding: 24px 0;
}
.who-we-are2 h2 {
    font-size: 24px;
}
.who-we-are-text2 h3 {
    font-size: 20px;

    margin-top: 10px;
}
.who-we-are-container2 {
    align-items: flex-start;
    height: fit-content;
}
}


@media screen and (min-width: 1024px) and (max-width: 1366px) {
.image-stack2 {
    position: relative;
    top: -254px;
    left: -27px;
  }
  
.image-stack__item img {
  width: 450px;
}
}