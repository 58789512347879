.hw-card{
    width: 100%;
    /* height: 471px; */
    margin-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 72px 72px 0px 0px;
    background: white;
    border-top: 2px solid #9BD6DB;
    padding: 40px 68px;

}

.hw-image img{
    width: 449px;
    max-width: 500px;
    height: 326px;
    /* margin-top: 18px; */
    margin-right: 40px;
    border-radius: 25px;
}


.hw-card-content{
    text-align: left;
    /* margin-top: 70px; */
}

.hw-card-content h2{
    font-size: 30px;
    font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0px;
}

.hw-card-content p{
    color: #464646;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.hw-subtitle{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 0px;
}



@media (max-width: 768px) {
    .hw-card {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        border-radius:0px 0px;
        border-top: none;
        padding: 10px 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .hw-image img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin-top: 0px;
        margin-right: 0px;
    }
    .hw-card-content {
        text-align: left;
        margin-top: 10px;
    }
    .hw-image{
        width: 100%;
    }
    .hw-subtitle {
        font-size: 16px;
        display: none;
    }
    .hw-card-content h2 {
        font-size: 20px;
    }
    .hw-card-content p {
        font-size: 16px;
    }
    .card-image{
        width: 100%;
    max-width: 100%;
    }
}