.love-web5-container {
   width: 100%;
   background: #060606;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: #fff;
   font-family: "Nunito Sans", sans-serif;
   text-align: center;
   padding-top: 40px;
   padding-bottom: 40px;
   box-sizing: border-box;

   overflow: hidden;
}
.experience-header{
   font-weight: 700;
   font-size: 34px
}
.header-content {
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.header-content h1 {
   font-size: 34px;
   font-weight: 600;
   text-transform: capitalize;
   max-width: 100%;
   top: -20px;
}

.stars {
   display: flex;
   justify-content: center;
   margin-bottom: 20px;


}

.star {
   color: #ffd700; /* Yellow color */
   font-size: 55px;
   padding-left: 10px;
   padding-right: 10px;
}

.icons-love {
   display: flex;
   justify-content: space-around;
   padding-left: 100px;
   padding-right: 100px;
   width: 100%;
}

.icon-love {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.icon-svg {
   width: 60px;
   height: 60px;
   margin-bottom: 8px;
   color:rgba(155, 214, 219, 1);
}

.icon-love span {
   color: #fff;
   font-family: "Lato", sans-serif;
   font-size: 30px;
   font-weight: 900;
   margin: 10px 0;
   width: 100%;
}

.icon-love p {
   color: white;
   font-family: "Lato", sans-serif;
   font-size: 22px;
   font-weight: 600;
   line-height: normal;
   text-transform: capitalize;
}
.icon-love p:first-of-type {
   color: white;
   font-size: 22px;
}

@media (max-width: 768px) {
   .love-web5-container {
       height: auto;
       padding: 16px;
       padding-top:24px;
       padding-bottom: 24px;
       align-items: normal;

   }

   .header-content h1 {
       font-size: 24px;
       margin-top: 0px;
   }
   .header-content {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px;
      
  }
   .star {
      margin-top: 0px;
      width: 40px;
      height: 40px;
   }
   .stars {
      margin-bottom: 0px;
   }
   .icon-love span {
       font-size: 12px;
       margin: 0px;
   }

   .icon-love p {
       font-size: 12px;
   }
   .icon-love p:first-of-type {
      color: white;
      font-size: 14px;
   }
   .icon-love {
      justify-items: center;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
  }
   .icons-love{
      padding-left: 0px;
      padding-right: 0px;
   }
   .icon-svg {
      margin: 10px;
      width: 40px;
      height: 40px;
  }
  .icon-love p{
   margin-bottom: 0px;
   margin-left: 5px;
  }
  .experience-header{
   font-weight: 700;
   font-size: 24px
}

}
