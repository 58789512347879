.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
}

.contact-form h4 {
  margin-bottom: 10px;
  text-align: left;
}

.contact-form p {
  text-align: left;
}

.contact-service-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.contact-service-buttons button {
  background-color: white;
  color: #22a8b3;
  border: 1px solid #22a8b3;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.contact-service-buttons button.active {
  background: #22a8b3;
  color: white;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
}

.form-group input,
.form-group select {
  width: calc(50% - 10px);
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin: 5px;
  color: grey;
}

.form-group input[type="tel"] {
  width: 50%;
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.privacy-policy {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 30px;
}

.privacy-policy p {
  width: 320px;
  text-align: left;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content img {
  margin-bottom: 15px;
}

.modal-content p {
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contact-service-buttons {
    display: block;
  }
  .form-group input,
  .form-group select {
    width: 100%;
  }
  .form-group {
    width: 100%;
    flex-direction: column;
  }
  .form-group input[type="tel"] {
    width: 100%;
  }
  .privacy-policy {
    margin-top: 20px;
    display: block;
    height: 40px;
    margin-bottom: 55px;
  }
  .contact-service-buttons button {
    font-size: 12px;
  }
  .privacy-policy p {
    width: 100%;
    margin-bottom: 0px;
  }
}
