.ourTeamCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-container-team {
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
  width: 200px;
  height: 200px;
  border: 1px solid #22A8B3;
  border-radius: 50%; /* Ensures it's a perfect circle */
  position: relative;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  
}

.card-team {
  width: 100%;
  height: 100%;
  position: relative;
}
.content-team p{
  font-size: 14px;
  line-height:120%;
  padding: 0px;
  margin: 0px;
  text-align: left;
}
.card-team .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  padding: 14px;
}

.card-team .front-content img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.card-team .front-content h5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  
  line-height: normal;
  text-transform: capitalize;
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.card-team .content-team {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: #21929B;
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 50%; /* Circular content */
  pointer-events: none;
  transform: translateY(100%); /* Start completely out of view */
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.card-team:hover .content-team {
  transform: translateY(0); /* Slide into view */
}

.card-team:hover .front-content {
  transform: translateY(-100%); /* Slide out of view */
}

.card-team:hover .front-content h5,
.card-team:hover .front-content img {
  opacity: 0; /* Fade out the front content */
}

.card-team:hover .front-content img {
  transform: scale(1.2); /* Slight zoom on hover */
}

.card-svg-team {
  margin-left: 12px;
}
@media (min-width: 1026px) and (max-width: 1257px) {
  .card-container-team {
    width: 150px;
    height: 150px;
  }
  .content-team p {
    font-size: 10px;
  }

}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .card-container-team {
    height: 181px;
    width: 181px;
  }
  .card-svg-team {
    margin-left: 10px;
  }
}
@media (max-width: 768px) {
  .card-container-team {
    width: 150px;
    height: 150px;
  }
  .card-team .front-content h5 {
    font-size: 16px;
  }
  .content-team p {
    font-size: 10px;
  }
  .card-team .front-content img {
    width: 36px;
    height: 36px;
  }
  .card-svg-team {
    transform: rotate(90deg);
    margin-top: 10px;
    margin-left: 0px;
    /* margin-top: 10px; */
}
}
