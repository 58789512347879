/* Card container and card */
.unique-card-container {
  width: 400px;
  height: 360px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 90% !important;
}

.unique-card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: 10px;
  cursor: pointer;
}
.unique-services-section h2{
  font-family: "Nunito Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 24px;
}
.slick-dots {
  display: none !important;
}
.unique-card .unique-front-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.9s cubic-bezier(0.23, 1, 0.320, 1);
}

.unique-card .unique-front-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  color: #000;
  margin-bottom: 20px;
}

.unique-card .unique-front-content img {
  width: 100%;
  height: 253px;
  border-radius: 10px;
}

.unique-card .unique-content {
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(90deg, #9BD6DB 0%, #21929B 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateX(-100%);
  transition: all 0.9s cubic-bezier(0.23, 1, 0.320, 1);
}

.unique-card .unique-content .unique-heading {
  font-size: 24px;
  font-weight: bold;
}

.unique-card:hover .unique-content {
  transform: translateX(0);
}

.unique-card:hover .unique-front-content {
  transform: translateX(-30%);
}

.unique-card:hover .unique-front-content h3 {
  opacity: 0;
}

.slick-arrow{
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: end;
}
.slick-prev::before, .slick-next::before {
  font-size: 25px;
}
.unique-services-section {
  padding: 40px 120px;
  background-color: #ffffff;
}

.unique-know-more-link {
  color: #e8e8e8;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.slick-prev::before, .slick-next::before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: rgb(33, 146, 155);
}
@media (max-width: 768px) {
  .unique-services-section {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .unique-card-container {
    width: 100%;
height: 300px;
    }
    .slick-slider {
      width: 90% !important;
    }
    .unique-services-section h2 {
      font-size: 24px;
      margin-bottom: 16px;
    }
    .unique-card .unique-front-content h3 {
      font-size: 18px;
    }
}
