.services {
  text-align: center;
  padding-top: 40px;
}

.services h2{
  color: #1C1F35;
text-align: center;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 32px;
}

.service-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 120px;
  padding-right: 120px;
}

.service-buttons button {
  background: none;
  border: 1px solid #ddd;
  color: #000;
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.service-buttons .arrow-button {
  background-color: #ddd;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(501px, 1fr));
  gap: 40px;
  row-gap: 0px;
  margin-left: 120px;
  margin-right: 120px;
}

/* Additional styling for the mobile view button */
.mobile-dropdown {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* .pagination button{
  color: white;
  background-color: #9BD6DB;
  padding: 6px 10px;
  border-radius: 50px;
  border: none;
} */
.left-page-btn2 {
  background-color: rgb(221, 221, 221);
  color: #8E8E8E;
  padding: 10px 14px;
  border-radius: 50px;
  border: none;
  margin: 0px 10px;
}
.left-page-btn2:hover{
  background-color: rgb(34, 168, 179);
  color: #fff;
}
.page-number2{
  padding: 8px 16px;
  border-radius: 50px;
  border: none;
  margin:0px 5px;
  background-color: rgb(221, 221, 221);
  color: #8E8E8E;
}
.page-number2.active{
  background-color: rgb(34, 168, 179);
  color: #fff;
}
.pagination span{
  display: flex;
  align-self: center;
  margin: 0px 10px;
}

.rmsc .gray {
  display: flex;
}
.regular-view{
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .service-buttons {
      flex-direction: column;
      padding-left: 0px;
      padding-right: 0px;
  }
  .services h2{
    margin-bottom: 16px;
  font-size: 24px;
  }

  .services {
      text-align: center;
      padding: 20px;
      padding-left: 0px;
      padding-right: 0px;
  }
  
  .card-grid {
      display: block;
      margin-left: 16px;
      margin-right: 16px;
      gap: 24px;
  }
  
  .service-buttons button {
      padding: 3px;
      font-size: 10px;
  }
  
  .service-buttons {
      display: none;
  }
  
  .mobile-dropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .mobile-dropdown button {
    border: 1px solid grey;
    border-radius: 20px;
    background-color: white;
    width: auto; /* Adjusts width based on content */
    height: auto; /* Adjusts height based on content */
    padding: 8px 16px; /* Optional: Adds padding for better appearance */
  }
  
  
  .service-buttons .arrow-button {
      display: none;
  }
  .checkboxes {
    display: flex;
    flex-direction: column;
    margin: 16px;
}
.checkboxes .label{
  margin: 4px;
}
.search-icon-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #8E8E8E;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.regular-view{
  padding-bottom: 0px;
}
}
