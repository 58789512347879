/* Blog.css */

.blogs {
    text-align: center;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .blogssheading{
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }
  .blogs-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .category{
    color: #1C1F35;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .categories {
    width: 611px;
    color: #8E8E8E;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #8E8E8E;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .blogCard-both{
    width: 70%;
  }
  .blog-main-card {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  .sideCard{
    width: 30%;
  }
  
  .blog-card-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 20px;
  }
  .categories span {
    cursor: pointer;
    
    }

    .search-icon input{
      margin-left: 5px;
      border: none !important;
    }

.pagination2{
  display: flex;
    align-items: center;
    justify-content: center;
}
  .left-page-btn{
    background-color: rgb(221, 221, 221);
    color: #8E8E8E;
    padding: 4px 7px;
    border-radius: 100px;
    border: none;
    margin: 0px 10px;
  }
  .left-page-btn i{
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-page-btn:hover{
    background-color: rgb(34, 168, 179);
    color: #fff;
  }

  .page-number{
    background-color: rgb(221, 221, 221);
    color: #8E8E8E;
    padding: 5px 12px;
    border-radius: 50px;
    border: none;
    margin: 0px 10px;
  }
  .active-btn{
    background-color: rgb(34, 168, 179);
    color: #fff;
    padding: 5px 12px;
    border-radius: 50px;
    border: none;
    margin: 0px 10px;
  }
  .sidecard2{
    height: 100%;
  }

  
  @media (max-width: 768px) {
    .blog-card-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
  }
    .blogs {
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 24px;
      padding-bottom: 24px;
  }
  .blog-main-card {
    display: block;
   
}
.blogs-top {
  display: flex;
  flex-direction: column;
}
.categories span {
cursor: pointer;

}
.blogCard-both{
  width: 100%;
}
.categories {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blogssheading {
  font-size: 24px;
  margin-bottom: 16px;
}
.search-icon {
  display: none;
}
.rmsc .dropdown-heading {
  width: 200px !important;
  height: 100%;
}
.sideCard{
  width: 100%;
}
.page-number {
  margin: 0px 3px;
}
.active-btn{
  margin: 0px 3px;
}
  }