.hows-card-container{
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
    background-image: url(../images/bgimage.jpg);
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center;

}
.how-heading{
    padding-bottom: 30px;
    color: #111;
    font-family: "Nunito Sans";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
/* 
.another-div{
    width: 100%;
    height: 300px;
} */


@media (max-width: 768px) {
    .hows-card-container{
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 24px;
        padding-bottom: 14px;
        background-image: none;
    }
    .how-heading {
        padding-bottom: 16px;
        font-size: 24px;
    }

}