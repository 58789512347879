.studyCaseContainer {
    text-align: center;
    padding: 40px 120px;
    background-color: #f8f9fa; /* Adjust the background color as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .studyCaseTitle {
    font-family: "Nunito Sans";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }
  
  .studyCaseDescription {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
    width: 877px;
  }
  
  .studyCaseCards {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  
  .studyCaseCard {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 33%;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  /* .studyCaseCard:hover {
    transform: scale(1.05);
  } */
  
  .studyCaseCardImage {
    width: 100%;

    height: 200px;
    margin-bottom: 10px;
  }
  
  .studyCaseCardTitle {
    font-size: 1.1em;
    font-weight: bold;
    margin: 0px 0px 10px 15px;
  }
  
  .studyCaseCardTag {
    display: inline-block;
    background-color: #22A8B3;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.6em;
    margin-right: 16px;
   margin-left: 15px;
   margin-bottom: 5px;
  }
  
  .studyCaseCardDescription {
    color: #777;
    padding: 0 15px 20px 15px;
    font-size: 1em;
  }
  

  @media (max-width: 768px) { 
    .studyCaseContainer {
      padding: 24px 16px;
    }
    .studyCaseDescription {
      font-size: 16px;
      margin-bottom: 16px;
      width: 100%;
      text-align: left;
    }
    .studyCaseCards {
      flex-direction: column;
      gap: 16px;
    }
    .studyCaseCard {

      width: 100%;
    }
    .studyCaseTitle {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }