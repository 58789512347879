.blogmain{
    display: flex;
    flex-direction: column; /* Ensure the cards are aligned horizontally */
    gap: 24px;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 40px;
}

.blogmain h3{
    color: #1C1F35;
font-family: "Nunito Sans";
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0px;
}

.blogmain-card1{
    width: 100%;
    height: 520px;
margin-bottom: 20px;
padding: 32px;
}

.blogmain-card1:hover{
    box-shadow: 0px 2.874px 17.246px -0.719px rgba(0, 0, 0, 0.20);
}

.blogmain-card2{
    display: flex;
    justify-content: space-between;
    gap: 48px;
    width: 100%;
    /* padding-top: 70px; */
    padding-bottom: 30px;
}

.blogmain-image{
    width: 50%;
    margin-right: 24px;
}
.blogmain-card2-content p:nth-of-type(2){
    margin-bottom: 10px;
    font-size: 18px;
}

.blogmain-image img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
.blogmain-content{
    width: 50%;
    text-align: left;
    display: block;
    align-content: center;
}
.blogmain-tag {
    display: inline-block;
    border-radius: 17px;
    background: #21929B;
    padding: 2px 10px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}
.blogmain-content p:nth-of-type(2){
    color: #464646;

font-family: "Nunito Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

.blogmain-card1{
    display: flex;
}



.blogmain-card2-content h5{
    color: #060606;
font-family: "Nunito Sans";
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: capitalize;
margin-top: 7px;
margin-bottom: 7px;
}


.know-more-blog img{
    width: 16px;
    height: 16px;
    margin-left: 2px;
}
.blogmain-card2-0,.blogmain-card2-1,.blogmain-card2-2 {
width: 33.33%;
/* height: 555px; */
    background: #FFF;
    /* margin: 10px; */
    padding: 16px;
    /* padding-left: 36px;
    padding-right: 36px; */
    padding-bottom: 24px;
    text-align: left;
    box-shadow: 0px 2.874px 17.246px -0.719px rgba(0, 0, 0, 0.20);
    border-radius: 20px 20px 0px 0px;
    cursor: pointer;
}
.blogmain-card2-0:hover,.blogmain-card2-1:hover,.blogmain-card2-2:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}


.blogmain-card2-img{
    width: 100%;
    height: 201px;
  
    margin-bottom: 10px;
    border-radius: 20px 20px 0px 0px
    /* border-radius: 20px; */
}


.blogmain-content h3{
font-size: 24px;
margin-top: 10px;
}
.blogmain-tag p{
    margin-bottom: 24px;
}
.know-more-blog{
    position: relative;
    font-size: 12px;
    padding: 5px;
    display: flex;
    font-weight: 800;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url(../images/Vector.svg);
    background-repeat: no-repeat;
    background-size: contain;
    color: rgba(0, 0, 0, 0.8); 
    overflow: hidden; 
    max-width: 100px;
    cursor: pointer;
}
.know-more-blog::after {
    content: ' ↗';
    margin-left: 8px;
}
.arrow-span{
    display: none;
}
.arrow{
    display: none;
}
.blogmain-card2-content{
    /* padding: 20px; */
    padding-bottom: 0px;
}

@media (max-width: 768px) {
    .blogmain{
        display: block;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 24px;
        padding-bottom: 20px;
    }

    .blogmain-card2{
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 16px;
        padding-bottom: 0px;
    }

    .blogmain-card2-0,.blogmain-card2-1,.blogmain-card2-2 {
        width: 100%;
        height: 100%;
        background: #FFF;
        box-shadow: 0px 2.874px 17.246px -0.719px rgba(0, 0, 0, 0.20);
        padding: 10px 15px;
        margin: 0px;
        margin-bottom: 10px;
        text-align: left;
        border-radius: 10px 10px 0px 0px
        
    }

    .blogmain-card2-img{
    
        margin-top: 0;
        margin-bottom: 0px;
        border-radius: 0px;
    }
    .blogmain-card1{
        display: block;
        height: 100%;
        box-shadow: 0px 2.874px 17.246px -0.719px rgba(0, 0, 0, 0.20);
        margin-top: 16px;
        margin-bottom: 0px;
        padding: 10px 15px;
    }

    .blogmain-image img {
        width: 100%;
        border-radius: 0px;
    }
    .blogmain-image{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .blogmain-content{
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .blogmain-card2-content{
        display: block;
    }
    /* .know-more-blog{
        display: none;
    } */

    .blogmain-content h3 {
        font-size: 20px;
        margin-top: 8px;
        margin-bottom: 2px;
    }

    .blogmain-tag {
        display: inline-block;
        border-radius: 20px;
        background: #21929B;
        padding: 0px 7px;
    }
    .blogmain-card2-content h5{
        font-size: 18px;
        margin-bottom: 0px;
        margin-top: 2px;
    }
    .blogmain-card2-content p{
        font-size: 12px;
        margin-bottom: 0px;
    }
    .blogmain-card2-content {
        display: block;
        align-content: center;
        padding-left: 10px;
    }
    .blogmain-card2-content p:nth-of-type(2) span{
        display: none;
    }
    .blogmain-card2-content p:nth-of-type(2){
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 1.4;
    }
    .arrow{
        display: none;

    }
    .arrow-span{
        display: none;
    }
.layer{
    width: 175px;
}
.blogmain-content p:nth-of-type(2) {
    margin-bottom: 0px;
    font-size: 16px;
  
}
.blogmain h3 {
    font-size: 24px;
}
.blogmain-card2-content{

    padding: 0px;
    padding-left: 6px;
}
}

