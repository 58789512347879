.about-us {
    padding-top: 40px;
  }

  
  .about-us-main p {
    padding-left: 209px;
    padding-right: 209px;
    color: #2F2F2F;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
  .about-us-main h2{
    margin-bottom: 0px;
    color: #1C1F35;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  
  .about-us-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .about-us-content img{
    width: 58%;
    height: 500px;
    display: flex;
    align-self: self-end;
    margin-top: 51px;
    margin-bottom: -180px;
  }
  .about-us-content span{
    font-family: Arial, sans-serif; /* You can choose any font you like */
    font-size: 120px; /* Adjust the size as needed */
    font-weight: bold;
    color: transparent;
    -webkit-text-stroke: 1px #00bcd4; /* Adjust the stroke color */
    text-transform: uppercase;
    text-orientation: mixed;
    line-height: 1;
    margin: 0;
    padding: 0;
    transform: rotate(270deg);
    padding-left: 420px;
    margin-left: -214px;
  }
  .ourvision {
    background-color: black;
    color: white;
    padding-top: 40px; /* Increase padding-top to accommodate overlap */
    padding-bottom: 20px;
  }
  

  
  .ourvision .ourvision-mainCard {
    display: flex;
    justify-content: space-around;
    padding-left: 120px;
    padding-right: 120px;
  }
  

  
  .ourvision-card1 .image1 {
    width: 218px;
    height: 408px;
    flex-shrink: 0;
  }
  
  .ourvision .image2 {
    width: 242px;
    height: 380px;
    flex-shrink: 0;
    position: absolute; 
    left: 120px;
    top: 80px;
    border-radius: 120px 120px;
  }
  .ourvision h3{
    color: #FFF;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0px;
  }
  
  .ourvision h5 {
    background-image: url(../images/Vector.svg);
    background-repeat: no-repeat;
    background-size: contain; 
    font-size: 15px;
    padding: 5px;
    color: black;
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-align: center;
  }
  .ourvision-card1 {
    display: flex;
    position: relative; /* Make this the containing block for absolute positioning */
    width: 420px;
    height: 460px;
    margin-top: 40px;
margin-bottom: 30px;
  }
  .ourvision-card2 {
    width: 420px;
    height: 460px;
    flex-shrink: 0;
    display:block;
    align-content: center;
    margin-top: 30px;
  }
  
  
  .ourvision-h4 {
    width: 420px;
    color: #FFF;
    font-size: 28px;
text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .ourvision-span{
    color: #fff;
font-family: "Nunito Sans";
font-size: 18px;
text-align: left;
font-style: normal;
font-weight: 300;
line-height: normal;
width: 420px;
margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    .ourvision {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .about-us-main p {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 16px;
        font-weight: 500;
    }
    .about-us {
      padding-top: 24px;
    }
    .about-us-content {
      padding-top: 12px;
      padding-bottom: 20px;
  }
    .ourMission-main-card{
        display: block;
        padding-top: 20px;
    
    }
    .ourvision-card1 .image1 {
    flex-shrink: 0;
    height: 330px;
    width: 188px;
}

    .ourvision-card1{
        height: auto;
        width: 100%;
        flex-shrink: 0;
        align-content: center;
        margin-bottom: 60px; 
        padding-left: 35px;
        /* padding-right: 50px; */
    }
    .ourvision-card2{
        width: 100%;
        height: auto;
    }
    .ourMission{
        padding-top: 20px;
        padding-bottom: 340px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .ourvision-span{
        font-size: 14px;
        width: 100%;
    }
    .ourvision-h4{
        width: 100%;
        font-size: 20px;

    }
    .ourvision .ourvision-mainCard {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
      }
      video {
        width: 100%;
      }
      .about-us-main h2 {
        font-size: 24px;
    }
    .video-wrapper {
      margin-top: 20px;
  }
  .ourvision h3 {
    font-size: 24px;
  }
  .ourvision .image2 {
    width: 180px;
  height: 300px;
}
  }
  @media only screen and (max-width: 768px) {
    .ourvision .image2 {
      left: 160px;
      
    }

}

/* Specific media query for very small mobile devices */
@media only screen and (max-width: 480px) {
    .ourvision .image2 {
      
        max-width: 180px; 
    }
}