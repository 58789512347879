.card-container {
    width: 100%;
    height: 510px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end; 
    justify-content: center; 
    margin: auto; 
  }
  
  .service-card {
    position: relative; 
    width: 100%;
    height: 410px;
    border-radius: 24px !important;
    background: rgba(158, 224, 230, 0.12) !important;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto; 
    border: none !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease; 
    cursor: pointer;
  }
  
  .service-card::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px; 
    background-color: #9BD6DB; 
    z-index: -1; 
    transition: bottom 0.3s ease; 
    opacity: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .service-card:hover::after {
    bottom: 0; 
    z-index: 1; 
    opacity: 1.5;
  }
  
  .service-card:hover {
    background: white !important; 
    box-shadow: 0 15px 19px rgba(0, 0, 0, 0.4);
  }
  
  .service-card:hover .card-image {
    transform: scale(1.5); 
  }
  
  
  .card-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .card-image {
    width: 100%;
    max-width: 250px;
    height: 190px;
    border-radius: 8px;
        transition: transform 0.3s ease-in-out; 
    transform-origin: center bottom;
  }

  
  .card-content {
    text-align: center;
    padding-bottom: 24px;
  }
  
  .card-content h3 {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .card-content p {
    font-size: 18px;
    color: #666;
  }
  




@media (max-width: 768px) {
    .card {
        width: 100%;
        height: auto;
        border-radius: 24px;
        background-color: rgba(106, 226, 237, 0.12);
    border: 1px solid #ddd;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    }

    .card-container {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        display: flex;
        align-items: flex-end; 
        justify-content: center; 
        margin: auto; 
        margin-bottom: 20px;
      }
      .service-card{
        width: 100%;
        height: 100%;
      }
      .service-card:hover {
        background:none !important; 
        box-shadow: none;
      }
        
  .service-card:hover .card-image {
    transform: none; 
  }
  .card-content h3 {
    font-size: 20px;
    margin: 5px 0;
    text-align: left;
  }
  .card-content p {
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
}
.service-card {
  background: none !important;
}

.service-card:hover {
  background: none !important;
}

.service-card:hover .card-image {
  transform: none;
}

  }
  