.who-we-are {
    padding: 50px 0;
    background-color: #fff;
    position: relative;
    text-align: left;
}

.who-we-are h2{
    text-align: center;
    color: #111;
    font-family: "Nunito Sans";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.who-we-are-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 0 120px;
    gap: 30px;
    height: 550px;
}

.image-stack {
    position: relative;
    top: -250px;
}

 .image-stack__item {
    position: absolute;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 10px;
} 

.image-stack__item--top {
    top: 85px;
    left: 62px;
    z-index: 3;
    transform: translate(20px, -20px);
}

.image-stack__item--middle {
    top: 53px;
    left: 46px;
    z-index: 2;
    transform: translate(10px, -10px);
}

.image-stack__item--bottom {
    top: 29px;
    left: 36px;
    z-index: 1;
}

.who-we-are-text {
    width: 50%;
    padding-left: 20px;
}

.who-we-are-text h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.who-we-are-text h3 {
    font-size: 22px;
    font-weight: 600;
    color: #21929B; /* Adjust color to match design */
    margin-bottom: 20px;
}

.who-we-are-text p {
    font-size: 16px;
    color: #6c757d;
    margin-bottom: 20px;
}

.stats {
    display: flex;
    gap: 40px;
    margin-bottom: 30px;
}

.stat h4 {
    font-size: 28px;
    color: #21929B;
    margin-bottom: 5px;
    font-weight: 800;
}

.stat p {
    font-size: 14px;
    color: #6c757d;
}

.btn-primary {
    background-color: #21929B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #1a737b;
}
