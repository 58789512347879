.maincontent {
  display: flex;
  text-align: left;
  padding-left: 120px;
  padding-right: 120px;
}

.content {
  flex: 1;
  padding: 20px;
}
.content h6 {
  color: #9bd6db;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
}
.content img {
  width: 100%;
}
.toc {
  /* width: 30%;
    height: 40%; */
  padding: 20px;
  background-color: #f4f4f4;

  overflow-y: auto;
}
.toc a {
  text-decoration: none;
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight: 500;
}
.toc ul li a {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section {
  margin-bottom: 25px;
}

.toc ul {
  list-style-type: none;
  padding-left: 0;
}

.toc li {
  padding: 5px 0;
}

.toc li.active a {
  color: #9bd6db;
  font-weight: bold;
}

.toc a {
  text-decoration: none;
  color: black;
}

.toc a:hover {
  text-decoration: underline;
  color: #9bd6db;
}
.toc-above{
  position: sticky;
  width: 30%;
}
.tableofcontent {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 98px;
}
.social-icons2 {
  /* top: 400px;
  position: sticky; */
   display:flex;
  margin-top: 20px;
  justify-content: space-evenly;
}
.social-icons2 .icon{
  width: 35px;
  height: 35px;
}
@media (max-width: 768px) {
  .toc {
    display: none;
  }
  .maincontent {
    display: flex;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .section {
    margin-bottom: 20px;
  }
  .content h2 {
    font-size: 20px;
    font-weight: 700;
  }
  .content p {
    font-size: 16px;
  }
  .toc-above {
    display: none;
  }

}
