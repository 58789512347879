.footer {
    position: relative;
    padding-top: 50px;
    padding-left: 120px;
    padding-right: 120px;
    overflow: hidden;
}

.new-data{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
                url(../images/footerimg.jpg);
                filter: blur(1px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.footer h3{
    color: #FFF;
font-size: 34px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
margin-bottom: 0px;
}

.footer h2{
    color: #FFF;
font-size: 48px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: capitalize;
margin-bottom: 20px;
display: flex;
align-items: center;
justify-content: center;
margin-top: 10px;
height: 60px;
overflow: hidden;
position: relative;
}

.footer h2 span{
    margin-top: 15px;
    color: #9BD6DB;
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
    /* width: 480px;
    text-align: left; */
    margin-left: 10px;
}

.footer .know-more {
    position: relative;
    font-size: 15px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url(../images/Vector.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: rgba(0, 0, 0, 0.8); 
    overflow: hidden; 
    cursor: pointer;
}



.footer-mainCard{
    display: flex;
   justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 37px;
    text-align: start;
}

.footer-card1{
    display: flex;
}

.footer-card1 img{
    width: 50px;
    height: 50px;
    margin-top: -13px;
}

.footer-card1-h1{
    color: #9BD6DB;
    font-family: "Nunito Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; 
    text-transform: capitalize;
    margin-bottom: 24px;
}


.footer-card2 p span{
    padding-right: 10px;
}

.footer-card2 p{
    white-space: pre;
    cursor: pointer;
}

.footer-card1 p{
    color: #ffffff;
width: 320px;
font-size: 16px;
font-style: normal;
font-weight: 300;
/* line-height: normal; */
text-transform: capitalize;
}

.footer-card2 h4,.footer-card3 h4, .footer-card4 h4{
    color: #9BD6DB;
font-size: 18px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: uppercase;
margin-bottom: 24px;
}


.footer-card2 p,.footer-card3 p, .footer-card4 p{
    color: #ffffff;
font-size: 16px;
font-style: normal;
font-weight: 400;
/* line-height: normal; */
margin-bottom: 20px;
cursor: pointer;
}

.all-icons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.all-icons span {
    color: white;
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
}

.footer-card4 hr{
    width: 299px;
height: 10px;
margin: auto;
flex-shrink: 0;
color:  #9BD6DB !important;
opacity: 0.5;
}
.footer-base{
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    height: 40px;
    background-color: black;
    color: white;
    display: flex;
    justify-content:center ;
}
/* .header-highlight-text{
    width: 440px;
    text-align: left;
    height: 50px;
} */

@media (max-width: 768px) {

    .footer {
        position: relative;
        padding-top: 20px;
        padding-left: 16px;
        padding-right: 16px;
        overflow: hidden;
        text-align: left;
    }
    .footer-mainCard{
        display: flex;
       flex-direction: column;
        padding-top: 40px;
        text-align: start;
    }

    .footer-card2, .footer-card3{
        display: grid;
        text-align: left;
    }

    .footer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .footer-card1-h1{
        color: #9BD6DB;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 77.778% */
    text-transform: capitalize;
    margin-bottom: 16px;
    }

    .footer-card2 h4,.footer-card3 h4, .footer-card4 h4{
        color: #9BD6DB;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    text-transform: uppercase;
    }
    
    
    .footer-card2 p,.footer-card3 p, .footer-card4 p{
     color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin-bottom: 16px;
    }

    .footer h2{
        font-size: 18px;
        font-weight: 400;
        height: auto;
    }
    .footer h2 span{
        font-size: 18px;
        font-weight: 400;
    }
    .footer h3{
        font-size: 16px;
        font-weight: 400;
    }
    .footer-card4{
        text-align: left;
    }

    
.all-icons {
    margin-top: 20px;
    display: flex;
    justify-content: normal;
}

.all-icons span {
    color: white;
    font-size: 16px;

    cursor: pointer;
}

.footer-card1 {
    display: block;
    text-align:left;
    padding-bottom: 20px;
}
.footer-card1 p{
font-size: 14px;
font-weight: 100;
width: 280px;

}
.footer-mainCard {
    padding-top: 16px;
}

.footer h5 {
    font-size: 12px;
    padding: 0px;
}
.footer .know-more {
    padding: 3px;
    padding-left: 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content:left;
    text-align: left;
    background-position: 0%;
}
.footer .know-more img{
width: 15px;
height: 15px;
padding: 2px;
}
.new-data {
    display: flex;
    justify-content: left;
    height: 63px;
    /* align-items: center; */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .footer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .footer-card1 h1 {
        font-size: 20px;
    }

    .footer-card1 p {
        font-size: 14px;
    }

    .footer-card2 h4, .footer-card3 h4, .footer-card4 h4 {
        font-size: 16px;
    }

    .footer-card2 p, .footer-card3 p, .footer-card4 p {
        font-size: 14px;
    }

    .footer h2 {
        font-size: 30px;
    }

    .footer h2 span {
        font-size: 28px;
    }

    .footer h3 {
        font-size: 24px;
    }
}
@media (min-width: 1025px) and (max-width: 1200px) {
    .footer {
        padding-left: 80px;
        padding-right: 80px;
    }

    .footer-card1 h1 {
        font-size: 22px;
    }

    .footer-card1 p {
        font-size: 15px;
    }

    .footer-card2 h4, .footer-card3 h4, .footer-card4 h4 {
        font-size: 18px;
    }

    .footer-card2 p, .footer-card3 p, .footer-card4 p {
        font-size: 15px;
    }

    .footer h2 {
        font-size: 36px;
    }

    .footer h2 span {
        font-size: 34px;
    }

    .footer h3 {
        font-size: 28px;
    }
}
