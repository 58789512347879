.ourNetwork {
  /* text-align: center; */
  /* margin: 50px 20px; */
  background-color: #050612;
  color: white;
  width: 100%;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 14px;
  overflow: hidden;
  box-sizing: border-box;
}
/* .ourNetwork h2{
    margin-left: 96px;
} */
/* .ourNetwork-main{
    margin: 60px 96px;
} */
.marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
  box-sizing: border-box;
}
.ourNetwork-main h5 {
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6; */
  text-align: center;
  border-radius: 50px;
  text-align: left;
  padding: 14px 20px;
  width: fit-content;
  background: linear-gradient(90deg, #9BD6DB 0%, #21929B 100%);
}

.marquee-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 200%; */
  /* Make sure the images fill the width */
}

.marquee-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.reverse .marquee-div {
  flex-direction: row-reverse;
}
/* .marquee-img:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease;
} */
.ourNetworks-heading {
  margin-bottom: 40px;
  font-family: "Nunito Sans";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 0 20px;
  padding: 10px 20px;
  border-radius: 40px;
  background-color: #494949;
  width: 168px;
  height: 60px;
  gap: 70px;
}

.icon-name {
  background: linear-gradient(90deg, #9bd6db 0%, #9ef8fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 14px; /* Adjust size as needed */
  margin-left: -62px;
}
.truffle {
  width: 30px;
  height: 30px;
}
.hardhat {
  width: 30px;
  height: 30px;
}
.near {
  width: 50px;
  height: 50px;
}
.vyper,
.brownie,
.webassembly,
.ethereum,
.harmony,
.oracle,
.rust,
.solidity,
.move,
.whiteblock,
.solana,
.telos,
.blockchain,
.ethereum,
.klaytn,
.ton,
.tezos {
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 768px) {
  .ourNetwork h2 {
    margin-left: 0px;
    text-align: center;
    font-size: 24px;
  }
  .ourNetwork {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
  }
  .ourNetwork-main {
    margin: 0px;
  }
.ourNetworks-heading{
    font-size: 24px;
    margin-bottom: 16px;
}
  .ourNetwork-main h5 {
    font-size: 16px;
  }
  .marquee-container {
    margin: 20px 0;
  }
  .icon-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin: 0 20px;
    padding: 10px 20px;
    border-radius: 40px;
    background-color: #494949;
    width: 138px;
    height: 50px;
  }
  .icon-name {
    font-weight: 500;
    font-size: 10px; 
    margin-left: -42px;
  }
}
